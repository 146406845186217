






































































































	import {Component, Vue} from 'vue-property-decorator';
	import HelloWorld from '@/components/HelloWorld.vue'

	@Component({components: {HelloWorld}})
	export default class Bs extends Vue {
		name = 'Dom'
		title = 'Dom'
	}
